import "./Rentals.css";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import wave from '../../assets/images/wave-tertiary.png';
import food1 from '../../assets/images/food1.jpg';
import food2 from '../../assets/images/food2.jpg';
import food3 from '../../assets/images/food3.jpg';
import scrollIcon from '../../assets/images/scroll-up-black.png';
import blackLogo from '../../assets/images/logo-black.png';

const RentalDetails = ({id}) => {
    const navigate = useNavigate();

    const rentalsDetails = require('../../rental-details.json');
    const rental = rentalsDetails.rentals[id - 1];
    const ref = useRef();

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    const img1 = require(`../../assets/images/${rental.name}1.jpg`);
    const img2 = require(`../../assets/images/${rental.name}2.jpg`);
    const img3 = require(`../../assets/images/${rental.name}3.jpg`);
    const img4 = require(`../../assets/images/${rental.name}4.jpg`);
    const img5 = require(`../../assets/images/${rental.name}5.jpg`);
    const img6 = require(`../../assets/images/${rental.name}6.jpg`);
    const img7 = require(`../../assets/images/${rental.name}7.jpg`);

    const icons = rental.summaryIcons.map((e, i)=> {
        const icon = require(`../../assets/images/icon_${e.icon}.png`);

        return <div className="grid auto-rows-max justify-items-center items-center gap-10" key={`icon-${i}`}>
            <img src={icon} className="rental-icons"/>
            <span className="min-w-60">{e.text}</span>
        </div>
    });

    return <section id="rental-detail" className="relative">
        <img src={wave} className="absolute wave w-full z-10" style={{maxHeight: "136px"}}/>
        <section className="grid justify-items-center pb-16 pt-36 px-44 bg-white relative">
            <img src={blackLogo} className="little-logo"/>
            <p className="italic-p">
                {rental.textBehindLogo}
            </p>
        </section>
        <section id="details-summary" className="grid items-center py-20 w-full overflow-hidden" style={{gridTemplateColumns: "max-content auto max-content"}}>
            <button onClick={() => scroll(-250)} className="appearence-none">
                <img src={scrollIcon} className="-rotate-90"/>
            </button>
            <div className="grid gap-8 items-center overflow-x-auto" ref={ref} style={{gridTemplateColumns: `repeat(${rental.summaryIcons.length}, minmax(min-content, 1fr))`}}>
                {icons}
            </div>
            <button onClick={() => scroll(250)} className="appearence-none">
                <img src={scrollIcon} className="rotate-90"/>
            </button>
        </section>
        <section className="bg-white pt-11 pb-36 px-24">
            <h2 className="title mb-20">
                {rental.textInH2}
            </h2>
            <div className="grid w-full gap-8" style={{gridTemplateColumns: "repeat(14, 1fr)", gridTemplateRows: "repeat(9, 1fr)", height: "710px"}}>
                <img className="col-span-3 row-span-3 object-cover object-center h-full w-full" src={img1} style={{borderRadius: "30px"}} />
                <img className="col-span-8 row-span-5 object-cover object-center h-full w-full" src={img4} style={{borderRadius: "30px"}} />
                <img className="col-span-3 row-span-6 object-cover object-center h-full w-full" src={img6} style={{borderRadius: "30px"}} />
                <img className="col-span-3 row-span-6 object-cover object-center h-full w-full" src={img2} style={{borderRadius: "30px"}} />
                <img className="col-span-2 row-span-4 object-cover object-center h-full w-full" src={img3} style={{borderRadius: "30px"}} />
                <img className="col-span-6 row-span-4 object-cover object-center h-full w-full" src={img5} style={{borderRadius: "30px"}} />
                <img className="col-span-3 row-span-3 object-cover object-center h-full w-full" src={img7} style={{borderRadius: "30px"}} />
            </div>
        </section>
        <section className="relative">
            <img src={wave} className="absolute wave w-full rotate-180 -top-24 z-10"/>
            <h1 className="title text-white pt-44 px-44 mb-40">
                En option ...
            </h1>
            <div className="grid grid-cols-2 gap-28 items-center px-24">
                <div className="relative grid grid-cols-3 gap-10" style={{height: "430px"}}>
                    <img src={logo} className="little-logo absolute -top-[125px] -right-[125px] z-10"/>
                    <img src={food1} className="food-preview w-full h-full object-cover object-center" />
                    <img src={food2} className="food-preview w-full h-full object-cover object-center" />
                    <img src={food3} className="food-preview w-full h-full object-cover object-center" />
                </div>
                <p className="text-white text-left">
                    Si vous le souhaitez, vous pouvez également déguster les plats délicieux préparés par la Cheffe Végétale du Domaine.
                </p>
            </div>
            <button className="reverse-colors mt-36 mb-44 mx-44" onClick={() => navigate('/contact')}>
                Réserver un hébergement
            </button>
        </section>
    </section>;
}

export default RentalDetails;