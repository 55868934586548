import "./NavBarMenu.css";
import { NavLink } from "react-router-dom";

const NavBarMenu = () => {
    return <nav className="block p-1.5 bg-white rounded-full">
        <ul className="nav__ul grid grid-flow-col auto-cols-max justify-items-center items-center">
            <li className="nav__li home h-full">
                <NavLink to="/"
                className={({isActive}) => (isActive ? "current" : "")}>
                    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" title="Accueil">
                        <path d="M27 13.2259V28C27 29.6569 25.6569 31 24 31H21.5C19.8431 31 18.5 29.6569 18.5 28V23.2787C18.5 21.6218 17.1569 20.2787 15.5 20.2787H12.5C10.8431 20.2787 9.5 21.6218 9.5 23.2787V28C9.5 29.6569 8.15685 31 6.5 31H4C2.34315 31 1 29.6568 1 28V13.2259C1 12.3349 1.39604 11.4901 2.08088 10.9201L12.0809 2.59725C13.1929 1.67173 14.8071 1.67173 15.9191 2.59725L25.9191 10.9201C26.604 11.4901 27 12.3349 27 13.2259Z" stroke="white" strokeWidth="2"/>
                    </svg>
                </NavLink>
            </li>
            <div className="grid grid-flow-col auto-cols-max gap-4 justify-items-center items-center">
                <li className="nav__li">
                    <NavLink to="/domaine"
                    className={({isActive}) => (isActive ? "current" : "")}>
                        <span>
                            Domaine
                        </span>
                    </NavLink>
                </li>
                <li className="nav__li">
                    <NavLink to="/locations"
                    className={({isActive}) => (isActive ? "current" : "")}>
                        <span>
                            Locations
                        </span>
                    </NavLink>
                </li>
                <li className="nav__li">
                    <NavLink to="/cuisine-et-école"
                    className={({isActive}) => (isActive ? "current" : "")}>
                        <span>
                            Cuisine & École
                        </span>
                    </NavLink>
                </li>
                <li className="nav__li">
                    <NavLink to="/évènements"
                    className={({isActive}) => (isActive ? "current" : "")}>
                        <span>
                            Évènements
                        </span>
                    </NavLink>
                </li>
                <li className="nav__li">
                    <NavLink to="/contact"
                    className={({isActive}) => (isActive ? "current" : "")}>
                        <span>
                            Contact
                        </span>
                    </NavLink>
                </li>
            </div>
        </ul>
    </nav>;
}



export default NavBarMenu;