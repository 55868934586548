import "./Contact.css";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../Logo';
import bg from '../../assets/images/view-hammock.jpg';
import whiteLogo from '../../assets/images/logo.svg';
import scrollDown from '../../assets/images/scroll-down.png';
import icon1 from '../../assets/images/icon_adress_white.png';
import icon2 from '../../assets/images/icon_phone_white.png';
import icon3 from '../../assets/images/icon_mail_white.png';

const contactSectionStyle = {
    backgroundImage: `url(${bg})`
}

const Contact = () => {
    const [mailAddress, setMailAddress] = useState("")
    const [topic, setTopic] = useState(null)
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const location = useLocation();
    return <section>
        <div id="domain-banner" style={contactSectionStyle} className="banner">
            <Logo />
            <HashLink smooth to={`${location.pathname}#contactez-nous`}>
                <img src={scrollDown} className="cursor-pointer"/>
            </HashLink>
        </div>
        <section id="contactez-nous">
            <section id="events-options" className="relative grid justify-items-center gap-16 px-16 pt-24 pb-40">
                <img src={whiteLogo} className="little-logo"/>
                <div className="flex flex-row flex-wrap gap-x-28 gap-y-32 items-center px-24">
                    <div className="relative flex-1">
                        <hgroup className="mb-28">
                            <h1 className="title text-left text-white mb-2">
                                Contactez- nous
                            </h1>
                            <p className="subtitle text-left text-white">
                                Réservations, questions <wbr/>...&nbsp;Dîtes-nous en plus&nbsp;!
                            </p>
                        </hgroup>
                        <address className="grid grid-flow-row auto-rows-fr gap-6">
                            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                                <img src={icon1} className="icon-contact"/>
                                <span className="text-white">
                                    1 lieu dit La Planette, La Romieu
                                </span>
                            </article>
                            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                                <img src={icon2} className="icon-contact"/>
                                <span className="text-white">
                                    06 31 32 82 66 - 06 78 71 30 83
                                </span>
                            </article>
                            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                                <img src={icon3} className="icon-contact"/>
                                <span className="text-white">
                                    martelflorence@rocketmail.com
                                </span>
                            </article>
                        </address>
                    </div>
                    <div className="relative flex-1">
                        <form className="bg-white py-14 px-9 grid gap-8">
                            <div>
                                <h2 className="mb-3 text-left">
                                    Je suis intéressé.e par&nbsp;:
                                </h2>
                                <div id="topic-list" className="flex flex-row flex-wrap gap-5">
                                    <input
                                        type="radio"
                                        id="topic_rent"
                                        name="topic"
                                        value="Location"
                                        checked={topic === "Location"}
                                        onChange={() => (setTopic("Location"))}
                                    />
                                    <label htmlFor="topic_rent">
                                        Location
                                    </label>
                                    <input
                                        type="radio"
                                        id="topic_cooking-lessons"
                                        name="topic"
                                        value="Cuisine"
                                        checked={topic === "Cuisine"}
                                        onChange={() => (setTopic("Cuisine"))}
                                    />
                                    <label htmlFor="topic_cooking-lessons">
                                        Cuisine
                                    </label>
                                    <input
                                        type="radio"
                                        id="topic_event"
                                        name="topic"
                                        value="Évènements"
                                        checked={topic === "Évènements"}
                                        onChange={() => (setTopic("Évènements"))}
                                    />
                                    <label htmlFor="topic_event">
                                        Évènements
                                    </label>
                                    <input
                                        type="radio"
                                        id="topic_other"
                                        name="topic"
                                        value="Autre"
                                        checked={topic === "Autre"}
                                        onChange={() => (setTopic("Autre"))}
                                    />
                                    <label htmlFor="topic_other">
                                        Autre
                                    </label>
                                </div>
                            </div>
                            <div className="grid auto-rows-max grid-flow-row gap-1.5 justify-items-start">
                                <label htmlFor="name">Ton nom</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="John Smith"
                                    value={name}
                                    onChange={(e) => (setName(e.target.value))}
                                />
                            </div>
                            <div className="grid auto-rows-max grid-flow-row gap-1.5 justify-items-start">
                                <label htmlFor="mailAddress">Ton email</label>
                                <input
                                    type="text"
                                    name="mailAddress"
                                    placeholder="email@gmail.com"
                                    value={mailAddress}
                                    onChange={(e) => (setMailAddress(e.target.value))}
                                />
                            </div>
                            <div className="grid auto-rows-max grid-flow-row gap-1.5 justify-items-start">
                                <label htmlFor="message">Ton message</label>
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={(e) => (setMessage(e.target.value))}
                                />
                            </div>
                            <button type="submit">
                                Envoyer le message
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </section>;
}

export default Contact;