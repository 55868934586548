import "./Events.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../Logo';
import bg from '../../assets/images/events-bg.png';
import scrollDown from '../../assets/images/scroll-down.png';
import blackLogo from '../../assets/images/logo-black.png';
import whiteLogo from '../../assets/images/logo.svg';
import wave from '../../assets/images/wave-tertiary.png';
import event1 from '../../assets/images/event1.jpeg';
import event2 from '../../assets/images/event2.jpg';

const eventsSectionStyle = {
    backgroundImage: `url(${bg})`
}

const Events = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return <section>
        <div id="domain-banner" style={eventsSectionStyle} className="banner">
            <Logo />
            <HashLink smooth to={`${location.pathname}#bien-être`}>
                <img src={scrollDown} className="cursor-pointer"/>
            </HashLink>
        </div>
        <section id="bien-être">
            <section className="grid justify-items-center pb-16 px-44 bg-white relative gap-16 pt-32" style={{paddingBottom: "189px"}}>
                <img src={blackLogo} className="little-logo"/>
                <p className="italic-p">
                    Yoga, massages, soins, cours de cuisine...
                    <br/>
                    Participez ou organisez des événements uniques au Domaine de La Planette.
                </p>
            </section>
            <section id="events-options" className="relative">
                <img src={wave} className="absolute wave w-full rotate-180 -top-24 z-10"/>
                <h1 className="title pt-44 px-44 mb-40 text-white">
                    2 options
                </h1>
                <div className="flex flex flex-row flex-wrap gap-x-28 gap-y-32 items-center px-24">
                    <div className="relative flex-1">
                        <img src={whiteLogo} className="little-logo absolute -top-[125px] absolute-h-center z-10"/>
                        <article className="event-option bg-white min-w-96">
                            <img src={event1} className="w-full h-full object-cover object-center"/>
                            <h2 className="mt-11 mb-7 px-3">
                                Vous souhaitez créer un événement ou un stage&nbsp;?
                            </h2>
                            <p className="px-10 text-left">
                                Le Domaine dispose de nombreux équipements et espaces uniques pour organiser tous vos événements. Envoyez vos demandes, on les <wbr/>réalisera&nbsp;!
                            </p>
                            <button className="outlined-black little-btn my-12" onClick={() => navigate('/contact')}>
                                Dites-nous en plus
                            </button>
                        </article>
                    </div>
                    <div className="relative flex-1">
                        <img src={whiteLogo} className="little-logo absolute -top-[125px] absolute-h-center z-10"/>
                        <article className="event-option bg-white min-w-96">
                            <img src={event2} className="w-full h-full object-cover object-center"/>
                            <h2 className="mt-11 mb-7 px-3">
                                Vous souhaitez participer à un événement ou un stage&nbsp;?
                            </h2>
                            <p className="px-10 text-left">
                                De nombreux événements et activités sont organisés au Domaine de La Planette.
                                <br/>
                                Ne loupez pas <wbr/>les prochains&nbsp;!
                            </p>
                            <button className="outlined-black little-btn my-12" onClick={() => navigate('/contact')}>
                                Découvrir
                            </button>
                        </article>
                    </div>
                </div>
                <button className="reverse-colors mt-36 mb-44 mx-44 size-auto py-4" onClick={() => navigate('/domaine')}>
                    En savoir plus sur le Domaine
                </button>
            </section>
        </section>
    </section>;
}

export default Events;