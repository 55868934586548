import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "./CookeryLessons.css";
import Logo from '../Logo';
import bg from '../../assets/images/cookery-lessons-bg.png';
import scrollDown from '../../assets/images/scroll-down.png';
import whiteLogo from '../../assets/images/logo.svg';
import blackLogo from '../../assets/images/logo-black.png';
import iconClose from '../../assets/images/icon_close_white.png';
import wave from '../../assets/images/wave-tertiary.png';
import imgMamain from '../../assets/images/flo.png';
import img1 from "../../assets/images/cooking1.jpg";
import img2 from "../../assets/images/cooking2.jpg";
import img3 from "../../assets/images/cooking3.jpg";
import img4 from "../../assets/images/cooking4.jpg";
import img5 from "../../assets/images/cooking5.jpg";
import img6 from "../../assets/images/cooking6.jpg";
import img7 from "../../assets/images/cooking7.jpg";
import workshop1 from "../../assets/images/workshop1.jpg";
import workshop2 from "../../assets/images/workshop2.jpg";
import workshop3 from "../../assets/images/workshop3.jpg";
import workshop4 from "../../assets/images/workshop4.jpg";
import workshop5 from "../../assets/images/workshop5.jpg";
import WorkshopDetails from './WorkshopDetails';

const cookeryLessonsSectionStyle = {
    backgroundImage: `url(${bg})`
}

const CookeryLessons = () => {
    const location = useLocation();
    const [currentWorkshop, setCurrentWorkshop] = useState(0);
    const [showHostPresentation, setShowHostPresentation] = useState(false);

    const hostModal = <div className="blured-background">
        <dialog open className="relative top-10">
            <section id="host-modal" className="modal grid items-center justify-items-center p-20 gap-12 w-max">
                <img src={whiteLogo} style={{height: "150px", width: "150px"}}/>
                <img src={iconClose} className="icon-close absolute right-11 top-11 cursor-pointer" onClick={() => (setShowHostPresentation(false))}/>
                <div className="overflow-y-auto w-full" style={{maxHeight: "calc(100vh - 150px - 10rem - 5rem - 3rem)"}}>
                    <p className="text-white text-left">
                        Après une expérience riche en activités diverses, c'est en 2014 que j'ouvre mes chambres d'hôtes en Corse, que j'y prépare des dîners, que les vacanciers diront apprécier. En 2016 je découvre la cuisine végétale et me lance dans la folle aventure d'artisan traiteur, proposant mes prestations de plats cuisinés ainsi que mes ateliers de cuisine. 
                        <br/>
                        <br/>
                        Forte de mon expérience, et souhaitant développer un projet global de vie avec mon mari Michel, nous quittons la Corse fin 2021 et nous installons dans le Gers, où nous créons un véritable espace de vie pour toutes nos activités&nbsp;: l'accueil pèlerin, les chambres d'hôtes, la location saisonnière, les ateliers et stages de cuisine végétale ainsi que les séjours bien-être. 
                        <br/>
                        <br/>
                        Entre temps, je me suis formée auprès des plus grands&nbsp;: Linda Vongdara pour la pâtisserie et Fabien Borgel pour la cuisine crue végétale, ainsi qu'auprès de chefs étoilés. J'espère que mon expérience saura ravir vos papilles et que vous apprécierez ce lieu, que Michel a entièrement rénové et que nous avons décoré et meublé avec soin&nbsp;!
                    </p>
                </div>
            </section>
        </dialog>
    </div>;

    return <section>
        <div id="domain-banner" style={cookeryLessonsSectionStyle} className="banner">
            <Logo />
            <HashLink smooth to={`${location.pathname}#découvrez-la-cuisine-végétale`}>
                <img src={scrollDown} className="cursor-pointer"/>
            </HashLink>
        </div>
        <section id="découvrez-la-cuisine-végétale">
            <section className="bg-white pt-16 pb-16 px-24">
                <h2 className="title mb-16">
                    Découvrez la cuisine végétale
                </h2>
                <div className="grid w-full gap-8" style={{gridTemplateColumns: "repeat(14, 1fr)", gridTemplateRows: "repeat(9, 1fr)", height: "710px"}}>
                    <img className="col-span-3 row-span-3 object-cover object-center h-full w-full" src={img1} style={{borderRadius: "30px"}} />
                    <img className="col-span-8 row-span-5 object-cover object-center h-full w-full" src={img4} style={{borderRadius: "30px"}} />
                    <img className="col-span-3 row-span-6 object-cover object-center h-full w-full" src={img6} style={{borderRadius: "30px"}} />
                    <img className="col-span-3 row-span-6 object-cover object-center h-full w-full" src={img2} style={{borderRadius: "30px"}} />
                    <img className="col-span-2 row-span-4 object-cover object-center h-full w-full" src={img3} style={{borderRadius: "30px"}} />
                    <img className="col-span-6 row-span-4 object-cover object-center h-full w-full" src={img5} style={{borderRadius: "30px"}} />
                    <img className="col-span-3 row-span-3 object-cover object-center h-full w-full" src={img7} style={{borderRadius: "30px"}} />
                </div>
            </section>
            <section className="grid justify-items-center pb-16 px-44 bg-white relative gap-16">
                <img src={blackLogo} className="little-logo"/>
                <p className="italic-p">
                    Lors de votre passage au Domaine vous pourrez avoir la chance de goûter aux plats délicieux de notre Cheffe Végétale professionnelle ou de participer à un Atelier de cuisine végétale.
                </p>
            </section>
            <section id="meet-the-host" className="py-44 px-28 grid gap-20" style={{gridTemplateColumns: "max-content auto"}}>
                <img src={imgMamain} />
                <div className="text-left">
                    <hgroup className="mb-6">
                        <h1 className="title text-left">
                            Rencontrez Florence
                        </h1>
                        <p className="subtitle text-left">
                            Cheffe Végétale Professionnelle
                        </p>
                    </hgroup>
                    <p className="text-left mb-10">
                        Passionnée de cuisine depuis toute petite, j'ai toujours eu à coeur de confectionner et partager des mets de qualité. 
                        <br/>
                        Mon parcours universitaire m'a longtemps éloignée de ma vocation première, mes valeurs et mes passions&nbsp;: accueillir et cuisiner. 
                    </p>
                    <button className="outlined-black" onClick={() => (setShowHostPresentation(true))}>
                        Lire la suite
                    </button>
                </div>
            </section>
            <section id="words-about-workshop" className="relative py-44">
                <img src={wave} className="absolute wave w-full rotate-180 -top-24 z-10"/>
                <p className="text-white px-28 italic-p">
                    Découvrez une nouvelle façon de cuisiner avec plaisir. Participez activement à la transition alimentaire ou simplement, prenez le temps de vous reconnecter à la nourriture éthique. En vous laissant guider par les conseils et l'assistance de Florence, devenez rapidement autonome et votre propre chef. Vous pourrez ainsi vous nourrir sainement, en respectant le Vivant et faire plaisir à vos proches en leur préparant des plats savoureux, totalement végétaux.
                </p>
            </section>
            <section id="workshops-presentation" className="text-white">
                <h1 className="title text-white">
                    Inscrivez-vous aux prochains Ateliers
                </h1>
                <div className="grid items-center py-20 pl-24 w-full overflow-hidden">
                    <div className="grid items-center overflow-x-auto gap-10" style={{gridTemplateColumns: "repeat(5, minmax(min-content, 1fr))"}}>
                        <article onClick={() => setCurrentWorkshop(1) }>
                            <img src={workshop1} />
                            <div>
                                <h2>
                                    Ateliers de cuisine
                                </h2>
                                <button className="outlined-white little-btn">
                                    En savoir plus
                                </button>
                            </div>
                        </article>
                        <article onClick={() => setCurrentWorkshop(2) }>
                            <img src={workshop2} />
                            <div>
                                <h2>
                                    Ateliers soirées
                                </h2>
                                <button className="outlined-white little-btn">
                                    En savoir plus
                                </button>
                            </div>
                        </article>
                        <article onClick={() => setCurrentWorkshop(3) }>
                            <img src={workshop3} />
                            <div>
                                <h2>
                                    Stages week-end
                                </h2>
                                <button className="outlined-white little-btn">
                                    En savoir plus
                                </button>
                            </div>
                        </article>
                        <article onClick={() => setCurrentWorkshop(4) }>
                            <img src={workshop4} />
                            <div>
                                <h2>
                                    Stage 4 jours
                                </h2>
                                <button className="outlined-white little-btn">
                                    En savoir plus
                                </button>
                            </div>
                        </article>
                        <article onClick={() => setCurrentWorkshop(5) }>
                            <img src={workshop5} />
                            <div>
                                <h2>
                                    Stage d'exception
                                </h2>
                                <button className="outlined-white little-btn">
                                    En savoir plus
                                </button>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            {currentWorkshop == 0 ? "" : <WorkshopDetails id={currentWorkshop} stateChanger={setCurrentWorkshop}/>}
            {showHostPresentation ? hostModal : ""}
        </section>
    </section>;
}

export default CookeryLessons;