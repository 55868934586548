import "./Footer.css";
import blackLogo from '../../assets/images/logo-black.png';
import icon1 from '../../assets/images/icon_adress.png';
import icon2 from '../../assets/images/icon_phone.png';
import icon3 from '../../assets/images/icon_mail.png';

const Footer = () => {
    return <footer className="px-28 py-16 grid grid-flow-col justify-between items-center">
        <img src={blackLogo} className="little-logo"/>
        <address className="grid grid-flow-row auto-rows-fr gap-6">
            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                <img src={icon1}/>
                <span>
                    1 lieu dit La Planette, La Romieu
                </span>
            </article>
            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                <img src={icon2}/>
                <span>
                    06 31 32 82 66 - 06 78 71 30 83
                </span>
            </article>
            <article className="grid grid-flow-col auto-cols-max gap-12 items-center">
                <img src={icon3}/>
                <span>
                    martelflorence@rocketmail.com
                </span>
            </article>
        </address>
    </footer>;
}

export default Footer;