import "./CookeryLessons";
import { useNavigate } from 'react-router-dom';
import iconClose from '../../assets/images/icon_close_white.png';

const WorkshopDetails = ({id, stateChanger}) => {
    const navigate = useNavigate();

    const workshopDetails = require('../../workshops.json');
    const workshop = workshopDetails.workshops[id - 1];

    const modalBg = require(`../../assets/images/workshop${workshop.id}.jpg`);
    const modalStyle = {
        backgroundImage: `url(${modalBg})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        maxHeight: "calc(155px + 30px)"
    };

    const details = workshop.details?.map((el1, i1)=> {
        const line = el1.line?.map((el2, i2) => (<span className={el2.className} key={`detail-${i1}-${i2}`}>
            {el2.text}
        </span>));
        return line ? [line, <br/>] : "";
    });

    const prices = workshop.prices?.map((el1, i1)=> {
        const line = el1.line?.map((el2, i2) => (<span className={el2.className} key={`price-${i1}-${i2}`}>
            {" "}{el2.text}
        </span>));
        return line ? [line, <br/>] : "";
    });
    
    const extraInfo = workshop.extraInfo?.map((el1, i1)=> {
        const line = el1.line?.map((el2, i2) => (<span className={el2.className} key={`infos-${i1}-${i2}`}>
            {el2.text}
        </span>));
        return line ? [line, <br/>] : "";
    });

    const program = workshop.program?.map((e, i)=> {
        const plan = !["", null].includes(e.plan) ? <><span className="italic text-left">
            {e.plan}
        </span><br/></> : "";
        return <p key={`program-${i}`}>
            <span className="text-left text-wrap">
                <span className="italic">
                    {e.date}
                </span>
                {plan}
            </span>
            <br/>
        </p>
    });
    return <div className="blured-background">
        <dialog open id="workshop-modal" className="relative top-10">
            <section className="modal" style={modalStyle}>
                <section id="workshop-banner" className="relative p-11 w-full" style={{paddingBottom: "74px"}}>
                    <h1 className="title text-white">
                        {workshop.name}
                    </h1>
                    <img src={iconClose} className="icon-close absolute absolute-l-center cursor-pointer right-11" onClick={() => (stateChanger(0))} style={{top: "calc(50% - 15px)"}}/>
                </section>
                <section className="relative block p-20 bg-white text-center w-full" style={{top: "-30px"}}>
                    <div className="overflow-y-auto w-full text-left" style={{wordWrap: "break-word", maxHeight: "calc(100vh - 125px - 160px - 5rem - 4rem - 53px)"}}>
                        <p className="italic text-left text-wrap">
                            {workshop.summary}
                        </p>
                        <h2 className="text-left text-wrap mt-7">
                            {workshop.slots}
                        </h2>
                        {details.length == 0 ? "" : <p className="text-left">{details}</p>}
                        {prices.length == 0 ? "" : <p className="mt-7">{prices}</p>}
                        <br/>
                        {workshop.attendees ? <p className="italic">{workshop.attendees}</p> : ""}
                        {extraInfo.length == 0 ? "" : <><br/><br/><p>{extraInfo}</p></>}
                        <h2 className="text-left text-wrap mt-7">
                            Programme{workshop.prices == [] ? ", Tarifs" : ""} & Dates à venir
                        </h2>
                        {program}
                    </div>
                    <button className="little-btn outlined-black mt-16" onClick={() => {navigate("/contact")}}>
                        Réserver
                    </button>
                </section>
            </section>
        </dialog>
    </div>;
}

export default WorkshopDetails;