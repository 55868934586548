import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import bg from '../../assets/images/home-bg.png';
import "./Home.css";

const homeSectionStyle = {
    backgroundImage: `url(${bg})`
}

const Home = () => {
    const navigate = useNavigate();

    return <section>
        <div id="home-banner" style={homeSectionStyle} className="banner">
            <Logo />
            <button type="button" onClick={() => navigate('/domaine')}>
                Bienvenue
            </button>
        </div>
    </section>;
}

export default Home;