import {Contact, CookeryLessons, Domain, Events, Footer, Home, NavBarMenu, Rentals} from './components';
import { Routes, Route } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="w-full h-full App">
      <header className="fixed absolute-center z-50">
        <NavBarMenu/>
      </header>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/domaine" element={<Domain/>} />
        <Route path="/locations" element={<Rentals/>} />
        <Route path="/cuisine-et-école" element={<CookeryLessons/>} />
        <Route path="/évènements" element={<Events/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
