import "./Logo.css";
import logo from '../../assets/images/logo.svg';

const Logo = () => {
    return <div id="logo-container" className="absolute absolute-center">
        <img id="logo" src={logo} alt="logo" className="absolute z-20"/>
        <div id="logo-background" className="z-10"></div>
    </div>;
}

export default Logo;