import "./Rentals.css";
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../Logo';
import bg from '../../assets/images/rentals-bg.png';
import scrollDown from '../../assets/images/scroll-down.png';
import hosting1 from '../../assets/images/hosting1.jpg';
import hosting2 from '../../assets/images/chambre4.jpg';
import hosting3 from '../../assets/images/view-cottage.jpg';
import icon1 from '../../assets/images/icon_people.png';
import icon2 from '../../assets/images/icon_time.png';
import RentalDetails from './RentalDetails'
import blackLogo from '../../assets/images/logo-black.png';
import iconClose from '../../assets/images/icon_close.png';

const rentalsSectionStyle = {
    backgroundImage: `url(${bg})`
}

const Rentals = () => {
    const navigate = useNavigate();
    const [currentRental, setCurrentRental] = useState(0);
    const [showRatesModal, setShowRatesModal] = useState(false);
    const location = useLocation();

    const modal = <div className="blured-background">
        <dialog open className="relative">
            <section id="rates-modal" className="modal absolute absolute-h-center">
                <section className="relative grid items-center justify-items-center py-10 px-20 gap-12 w-max">
                    <img src={blackLogo} style={{height: "150px", width: "150px"}}/>
                    <img src={iconClose} className="icon-close absolute right-11 top-11 cursor-pointer" onClick={() => (setShowRatesModal(false))}/>
                    <table id="rates-table">
                        <thead>
                            <tr>
                                <th>
                                    Tarifs
                                </th>
                                <th>
                                    Juillet & Août
                                </th>
                                <th>
                                    Hors-saison
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <b>Pavillon</b> <i>(3 personnes)</i>
                                </td>
                                <td>
                                    <b>450€/sem</b> <i>(linge de lit compris)</i>
                                </td>
                                <td>
                                    <b>350€/sem</b> <i>(bois de chauffage et linge de lit compris)</i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Supplément ménage
                                </td>
                                <td>
                                    <b>60€</b>
                                </td>
                                <td>
                                    <b>60€</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Supplément serviettes de bain <i>(lot de 2&nbsp;: 1 grande, 1 petite)</i>
                                </td>
                                <td>
                                    <b>5€</b>
                                </td>
                                <td>
                                    <b>5€</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Chambre d'hôtes</b> <i>(2 personnes)</i>
                                </td>
                                <td>
                                    <b>80€/nuit</b> <i>(petit déjeuner compris)</i>
                                </td>
                                <td>
                                    <b>65€/nuit</b> <i>(petit déjeuner compris)</i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Accès bain nordique et 2 vélos électriques
                                </td>
                                <td>
                                    <b>Gratuit</b>
                                </td>
                                <td>
                                    <b>Gratuit</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Maison de famille</b> <i>(9 personnes)</i>
                                </td>
                                <td>
                                    <b>800€/sem</b> <i>(linge de lit compris)</i>
                                </td>
                                <td>
                                    <b>700€/sem</b> <i>(bois de chauffage et linge de lit compris)</i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Supplément ménage
                                </td>
                                <td>
                                    <b>80€</b>
                                </td>
                                <td>
                                    <b>80€</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </section>
        </dialog>
    </div>

    return <section>
        <div id="domain-banner" style={rentalsSectionStyle} className="banner">
            <Logo/>
            <HashLink smooth to={`${location.pathname}#des-hébergements-uniques`}>
                <img src={scrollDown} className="cursor-pointer"/>
            </HashLink>
        </div>
        {showRatesModal ? modal : ""}
        <section id="des-hébergements-uniques" className={currentRental == 0 ? "grid justify-items-center py-20" : "grid justify-items-center py-20 pb-10"}>
            <h1 className={currentRental == 0 ? "title mb-36 text-white" : "title mb-24 text-white"}>
                Des hébergements uniques
            </h1>
            <div className="flex flex-row flex-wrap gap-10 mb-32 place-items-center">
                <article className="grid grid-flow-row auto-rows-max bg-white cursor-pointer" onClick={() => {setCurrentRental(1)}}>
                    <img src={hosting1}/>
                    <div className="grid grid-flow-row auto-rows-max p-11 pt-0 transition-all ease-in duration-300">
                        <button className={currentRental == 1 ? "link justify-self-center mt-7" : "hidden link justify-self-center mt-7"} onClick={() => (setShowRatesModal(true))}>
                            Voir les tarifs
                        </button>
                        <h2 className="mt-11">
                            Pavillon
                        </h2>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon1} className="icon" />
                            <span>
                                3 personnes
                            </span>
                        </div>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon2} className="icon" />
                            <span>
                                1 semaine
                            </span>
                        </div>
                        <button className={currentRental == 1 ? "justify-self-center mt-6" : "hidden justify-self-center mt-6"} onClick={() => navigate('/contact')}>
                            Réserver
                        </button>
                    </div>
                </article>
                <article className="grid grid-flow-row auto-rows-max bg-white cursor-pointer" onClick={() => {setCurrentRental(2)}}>
                    <img src={hosting2}/>
                    <div className="grid grid-flow-row auto-rows-max p-11 pt-0 transition-all ease-in duration-300">
                        <button className={currentRental == 2 ? "link justify-self-center mt-7" : "hidden link justify-self-center mt-7"} onClick={() => (setShowRatesModal(true))}>
                            Voir les tarifs
                        </button>
                        <h2 className="mt-11">
                            Chambre d'hôtes   
                        </h2>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon1} className="icon" />
                            <span>
                                2 personnes
                            </span>
                        </div>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon2} className="icon" />
                            <span>
                                1 nuit
                            </span>
                        </div>
                        <button className={currentRental == 2 ? "justify-self-center mt-6" : "hidden justify-self-center mt-6"} onClick={() => navigate('/contact')}>
                            Réserver
                        </button>
                    </div>
                </article>
                <article className="grid grid-flow-row auto-rows-max bg-white cursor-pointer" onClick={() => {setCurrentRental(3)}}>
                    <img src={hosting3}/>
                    <div className="grid grid-flow-row auto-rows-max p-11 pt-0 transition-all ease-in duration-300">
                        <button className={currentRental == 3 ? "link justify-self-center mt-7" : "hidden link justify-self-center mt-7"} onClick={() => (setShowRatesModal(true))}>
                            Voir les tarifs
                        </button>
                        <h2 className="mt-11">
                            Maison de famille
                        </h2>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon1} className="icon" />
                            <span>
                                9 personnes
                            </span>
                        </div>
                        <div className="grid grid-flow-col auto-cols-max gap-2 mt-6">
                            <img src={icon2} className="icon" />
                            <span>
                                1 semaine
                            </span>
                        </div>
                        <button className={currentRental == 3 ? "justify-self-center mt-6" : "hidden justify-self-center mt-6"} onClick={() => navigate('/contact')}>
                            Réserver
                        </button>
                    </div>
                </article>
            </div>
            <svg width="114" height="20" viewBox="0 0 114 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill={currentRental == 1 ? "white" : "black"} className="cursor-pointer" onClick={() => {setCurrentRental(1)}}/>
                <circle cx="57" cy="10" r="10" fill={currentRental == 2 ? "white" : "black"} className="cursor-pointer" onClick={() => {setCurrentRental(2)}}/>
                <circle cx="104" cy="10" r="10" fill={currentRental == 3 ? "white" : "black"} className="cursor-pointer" onClick={() => {setCurrentRental(3)}}/>
            </svg>
            {currentRental == 0 ? "" : <RentalDetails id={currentRental} key={currentRental}/>}
        </section>
    </section>;
}

export default Rentals;