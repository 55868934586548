import { useLocation, useNavigate } from 'react-router-dom';
import "./Domain.css";
import Logo from "../Logo";
import scrollDown from '../../assets/images/scroll-down.png';
import icon1 from '../../assets/images/icon_kitchen.png';
import icon2 from '../../assets/images/icon_tree.png';
import icon3 from '../../assets/images/icon_castle.png';
import wave from '../../assets/images/Wave.png';
import thumbnail1 from '../../assets/images/maison6.jpg';
import thumbnail2 from '../../assets/images/view-school.jpg';
import thumbnail3 from '../../assets/images/view-garden.jpg';
import thumbnail4 from '../../assets/images/view-cottage.jpg';
import thumbnail5 from '../../assets/images/view-hammock.jpg';
import thumbnail6 from '../../assets/images/view-guesttable.jpg';
import collegiale from '../../assets/images/collegiale.jpg';
import blackLogo from '../../assets/images/logo-black.png';
import { HashLink } from 'react-router-hash-link';


const domainSectionStyle = {
    backgroundImage: `url(${thumbnail1})`
}

const Domain = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return <section>
        <div id="domain-banner" style={domainSectionStyle}  className="banner">
            <Logo/>
            <HashLink smooth to={`${location.pathname}#un-lieu-unique`}>
                <img src={scrollDown} className="cursor-pointer"/>
            </HashLink>
        </div>
        <section id="un-lieu-unique" className="grid p-16 gap-16">
            <h1 className="title">
                Un lieu unique
            </h1>
            <div className="activity-summary grid grid-flow-col auto-cols-fr gap-14">
                <div className="grid gap-14 justify-items-center items-center">
                    <img src={icon1}/>
                    <span>
                        Chef Végétale professionnelle
                    </span>
                </div>
                <div className="grid gap-14 justify-items-center items-center">
                    <img src={icon2}/>
                    <span>
                        Lieu calme au <br/>milieu des arbres
                    </span>
                </div>
                <div className="grid gap-14 justify-items-center items-center">
                    <img src={icon3}/>
                    <span>
                        À proximité d’un patrimoine unique
                    </span>
                </div>
            </div>
        </section>
        <img src={wave} className="absolute wave w-full"/>
        <section id="domain-description" className="grid p-16 gap-16 pt-44">
            <h1 className="title">
                Idéal pour se ressourcer...
            </h1>
            <div className="grid grid-flow-col auto-cols-fr gap-16">
                <div className="grid grid-flow-row auto-rows-fr gap-8">
                    <img className="picture-thumbnail" src={thumbnail1}/>
                    <img className="picture-thumbnail" src={thumbnail2}/>
                    <img className="picture-thumbnail" src={thumbnail3}/>
                    <img className="picture-thumbnail" src={thumbnail4}/>
                    <img className="picture-thumbnail" src={thumbnail5}/>
                    <img className="picture-thumbnail" src={thumbnail6}/>
                </div>
                <p className="text-left">
                    Situé au bout d'un chemin pittoresque, notre domaine d'hébergement est un écrin de verdure et de sérénité, une bulle d'oxygène dédiée à tous les amoureux de la nature.
                    <br/>
                    <br/>
                    Michel et moi (Florence, Cheffe de cuisine végétale), avons restauré ce petit hameau, pour en faire un lieu de vie à partager. Michel, artisan-artiste, tailleur de pierre, mais aussi mosaïste, sculpteur, restaurateur de patrimoine public comme privé, a mis tout son coeur dans des travaux gigantesques de réhabilitation des bâtiments d'une ancienne ferme.
                    <br/>
                    <br/>
                    Nous souhaitons faire partager le résultat au plus grand nombre, à celles et ceux qui envisagent leurs vacances dans l'authenticité et les valeurs humaines, dans la vie campagnarde et dans la beauté d'une région et d'un lieu unique.
                    <br/>
                    <br/>
                    Bienvenue chez nous&nbsp;!
                </p>
            </div>
        </section>
        <section id="region-presentation" className="relative p-32">
            <img src={blackLogo} className="little-logo absolute-h-center absolute -top-[125px]"/>
            <div className="grid grid-flow-col auto-cols-fr gap-16">
                <div className="grid grid-flow-row auto-rows-max gap-7">
                    <h1 className="title text-left">
                        Et découvrir la région du Gers
                    </h1>
                    <p className="text-left">
                        De là, vous pourrez découvrir toute la région, les plus beaux villages de France, les festivités estivales, les expositions ...
                        <br/>
                        <br/>
                        Ou simplement suivre les recommandations des hôtes pour vos ballades et partir à l'aventure sur les chemins de randonnée tout proches.
                    </p>
                </div>
                <img src={collegiale} className="region-thumbnail"/>
            </div>
            <button className="reverse-colors mt-36" onClick={() => (navigate("/locations"))}>
                Découvrir les hébergements
            </button>

        </section>
    </section>;
}

export default Domain;